/** Dev / Debug / Internal feature used in Cerberus Explorer and Property State Storybook */
import React, { useState } from 'react';

import { AddressSearch } from '@hcs/address-search';
import {
  CerberusApiDocsLink,
  QUERY_PROPERTY_STATE_MEDIA,
  QUERY_PROPERTY_TRANSACTION_HISTORY,
} from '@hcs/cerberus';
import {
  ActionButtons,
  Button,
  CardTabs,
  JsonViewer,
  MultiSearch,
  NullState,
  Tabs,
  TabsProps,
} from '@hcs/design-system';
import { useCopyToClipboard, useUrlState } from '@hcs/hooks';
import { ApplicationType } from '@hcs/types';
import {
  PropertyStateCerberusInput,
  PropertyStateCore,
  PropertyStateMedia,
  PropertyStatePreview,
  PropertyStateSources,
  PropertyStateType,
  PropertyTransactionHistory,
} from '@hcs/types';

import { QUERIES_BY_TYPE_BY_SOURCE_FOR_DOCS } from '../../constants/propertyState.constants';
import { PropertyStateImageSection } from '../../features/PropertyStateImageSection/PropertyStateImageSection';
import { PropertyStateSummaryHeader } from '../../features/PropertyStateSummaryHeader/PropertyStateSummaryHeader';
import { PropertyStateTransactionHistoryTable } from '../../features/PropertyStateTransactionHistoryTable';
import { usePropertyState } from '../../hooks/usePropertyState';
import { usePropertyStateMedia } from '../../hooks/usePropertyStateMedia';
import { usePropertyTransactionHistory } from '../../hooks/usePropertyTransactionHistory';
import { PropertyStateTypeDropdown } from '../PropertyStateTypeDropdown';

import styles from './PropertyStateDebugger.module.css';

interface PropertyStateDebuggerState {
  hcAddressId?: number;
  propertyStateType?: PropertyStateType;
  propertyStateSource?: PropertyStateSources;
  application?: ApplicationType;
}
const dataHcName = 'cerberus-property-state-debugger';
const URL_STATE_ID = 'cerberus-property-state-debugger';
const INITIAL_STATE: PropertyStateDebuggerState = {
  propertyStateSource: PropertyStateSources.HC,
  propertyStateType: PropertyStateType.Core,
};
const DEFAULT_PROPERTY_STATE_TYPE = PropertyStateType.Core;
const DataViewer = ({
  propertyStateCerberusInput,
  isLoading,
  isError,
  rootName,
  data,
  query,
  feature,
}: {
  propertyStateCerberusInput: PropertyStateCerberusInput | undefined | null;
  isLoading: boolean;
  isError: boolean;
  rootName: string;
  query: string;
  feature?: JSX.Element;
  data:
    | PropertyStatePreview
    | PropertyStateCore
    | PropertyStateMedia
    | PropertyTransactionHistory
    | undefined
    | null;
}) => {
  const copyToClipboard = useCopyToClipboard();
  const [activeTab, setActiveTab] = useState<'data' | 'query' | 'feature'>(
    'data',
  );
  console.log('PropertyState  =', data);
  console.log('------------------------------------');
  const tabs: TabsProps<'data' | 'query' | 'feature'>['tabs'] = [
    {
      label: 'Simplified Result',
      value: 'data',
      content: (
        <JsonViewer
          dataHcName={`${dataHcName}-response`}
          isLoading={isLoading}
          rootName={rootName}
          error={
            isError ? (
              <NullState
                dataHcName={`${dataHcName}-error`}
                title={`An Error Occurred`}
              />
            ) : null
          }
          data={data}
        />
      ),
    },
    {
      label: 'Cerberus Query',
      value: 'query',
      content: (
        <pre>
          <code>{query}</code>
        </pre>
      ),
    },
  ];
  if (feature) {
    tabs.push({
      label: 'React Feature',
      value: 'feature',
      content: feature,
    });
  }
  return (
    <>
      {propertyStateCerberusInput && (
        <PropertyStateSummaryHeader
          propertyStateCerberusInput={propertyStateCerberusInput}
        />
      )}
      <Tabs
        searchParamId="results-tabs"
        dataHcName={`${dataHcName}-result-tabs`}
        className={styles.Tabs}
        active={activeTab}
        onChange={setActiveTab}
        tabs={tabs}
      >
        <ActionButtons dataHcName={`${dataHcName}-actions`}>
          <CerberusApiDocsLink
            query={query}
            queryLinkReplace={[
              'lookup(id: $cerberusInput)',
              `lookup(id: { hcAddressId: ${propertyStateCerberusInput?.cerberusInput?.hcAddressId} })`,
            ]}
          />
          <Button
            dataHcName={`${dataHcName}-copy-query`}
            label="Copy Query"
            onClick={() => {
              copyToClipboard(query);
            }}
          />
        </ActionButtons>
      </Tabs>
    </>
  );
};
interface PreviewProps {
  propertyStateCerberusInput: PropertyStateCerberusInput;
}
const DebugRender = ({ propertyStateCerberusInput }: PreviewProps) => {
  const propertyStateQuery = usePropertyState(propertyStateCerberusInput);
  const propertyStateMediaQuery = usePropertyStateMedia(
    propertyStateCerberusInput,
  );
  const propertyTransactionHistoryQuery = usePropertyTransactionHistory(
    propertyStateCerberusInput.cerberusInput,
  );
  const QUERY_PROPERTY_STATE =
    QUERIES_BY_TYPE_BY_SOURCE_FOR_DOCS[
      propertyStateCerberusInput.propertyStateType ||
        DEFAULT_PROPERTY_STATE_TYPE
    ][
      propertyStateCerberusInput.propertyStateSource || PropertyStateSources.HC
    ];
  return (
    <CardTabs
      dataHcName={`${dataHcName}-results`}
      searchParamId="propertyStateDebugger"
      tabs={[
        {
          label: 'PropertyState',
          tabId: 'propertyState',
          content: (
            <DataViewer
              {...propertyStateQuery}
              propertyStateCerberusInput={propertyStateCerberusInput}
              rootName={`PropertyState${propertyStateQuery.data?.propertyStateType}`}
              data={propertyStateQuery.data?.propertyState}
              query={QUERY_PROPERTY_STATE}
            />
          ),
        },
        {
          label: 'PropertyStateMedia',
          tabId: 'media',
          content: (
            <DataViewer
              {...propertyStateMediaQuery}
              propertyStateCerberusInput={propertyStateCerberusInput}
              rootName="PropertyStateMedia"
              query={QUERY_PROPERTY_STATE_MEDIA}
              feature={
                <PropertyStateImageSection
                  propertyStateCerberusInput={propertyStateCerberusInput}
                />
              }
            />
          ),
        },
        {
          label: 'PropertyTransactionHistory',
          tabId: 'transactionHistory',
          content: (
            <DataViewer
              {...propertyTransactionHistoryQuery}
              propertyStateCerberusInput={propertyStateCerberusInput}
              rootName="PropertyTransactionHistory"
              query={QUERY_PROPERTY_TRANSACTION_HISTORY}
              feature={
                propertyStateCerberusInput.cerberusInput && (
                  <PropertyStateTransactionHistoryTable
                    cerberusInput={propertyStateCerberusInput.cerberusInput}
                    showPriceChanges
                  />
                )
              }
            />
          ),
        },
      ]}
    />
  );
};

export const PropertyStateDebugger = () => {
  const {
    state: { hcAddressId, propertyStateType, propertyStateSource, application },
    actions: { patchUrlState },
  } = useUrlState(URL_STATE_ID, INITIAL_STATE);
  const [hcAddressIdInputValue, setHcAddressIdInputValue] = useState(
    hcAddressId?.toString() || '',
  );
  const propertyStateCerberusInput: PropertyStateCerberusInput | undefined =
    hcAddressId
      ? {
          cerberusInput: {
            hcAddressId,
            application,
          },
          propertyStateSource,
          propertyStateType,
        }
      : undefined;

  const handleUpdateHcAddressId = (newHcAddressId: number) => {
    patchUrlState([
      {
        op: 'add',
        path: '/hcAddressId',
        value: newHcAddressId,
      },
    ]);
    setHcAddressIdInputValue(newHcAddressId.toString());
  };
  return (
    <>
      <div className={styles.SearchSection}>
        <div className={styles.Search}>
          <MultiSearch
            dataHcName={`${dataHcName}-search`}
            className={styles.MultiSearch}
            inputs={[
              {
                input: (
                  <AddressSearch
                    inputStyle="transparent"
                    placeholder="Search for an Address"
                    onSelect={(addressSearchHit) => {
                      handleUpdateHcAddressId(Number(addressSearchHit.id));
                    }}
                  />
                ),
                label: 'Address',
                value: 'address',
              },
              {
                input: (
                  <input
                    className={styles.InvisibleInput}
                    value={hcAddressIdInputValue}
                    onChange={(e) => setHcAddressIdInputValue(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleUpdateHcAddressId(Number(hcAddressIdInputValue));
                      }
                    }}
                  />
                ),
                label: 'HC Address ID',
                value: 'hcAddressId',
              },
            ]}
          />
        </div>
        <PropertyStateTypeDropdown
          className={styles.PropertyStateTypeDropdown}
          value={
            propertyStateCerberusInput?.propertyStateType ||
            DEFAULT_PROPERTY_STATE_TYPE
          }
          onSelect={(value) => {
            patchUrlState([
              {
                op: 'add',
                path: '/propertyStateType',
                value,
              },
            ]);
          }}
        />
      </div>
      {propertyStateCerberusInput ? (
        <DebugRender propertyStateCerberusInput={propertyStateCerberusInput} />
      ) : null}
    </>
  );
};
