import React, { forwardRef, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';

import { Skeleton } from '../../../global/loading-errors-null/Skeleton';
import { CardTitle } from '../CardTitle';

import styles from './Card.module.css';

interface CardTheme {
  Card?: string;
  Content?: string;
}
interface MediaConfigVert {
  height: number;
  content: ReactNode;
  isLoading?: boolean;
  horizontal?: false;
  width?: number;
}
interface MediaConfigHorz {
  horizontal: true;
  width: number;
  content: ReactNode;
  isLoading?: boolean;
  height?: number;
}
type MediaConfig = MediaConfigVert | MediaConfigHorz;
export interface CardProps {
  dataHcName: string;
  dataHcEventSection?: string;
  className?: string;
  title?: ReactNode;
  media?: MediaConfig;
  children: ReactNode;
  noPadding?: boolean;
  noGap?: boolean;
  maxWidth?: number;
  minWidth?: number;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  smallPadding?: boolean;
  noBoxShadow?: boolean;
  theme?: CardTheme;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      dataHcName,
      dataHcEventSection,
      className,
      noPadding,
      noGap,
      smallPadding,
      noBoxShadow,
      maxWidth,
      minWidth,
      title,
      media,
      children,
      onMouseEnter,
      onMouseLeave,
      onClick,
      theme,
    }: CardProps,
    ref,
  ) => {
    const contentClassNames = classNames(styles.contentFlex, theme?.Content, {
      [styles.noPadding]: noPadding,
      [styles.padding]: !noPadding && !smallPadding,
      [styles.smallPadding]: !noPadding && smallPadding,
      [styles.noGap]: noGap,
    });
    const mediaContent = media && (
      <>
        <div
          style={
            media.horizontal
              ? {
                  width: `${media.width}px`,
                  flex: `0 0 ${media.width}px`,
                }
              : { height: `${media.height}px`, flex: `0 0 ${media.height}px` }
          }
          data-hc-name={`${dataHcName}-media`}
          className={styles.Media}
        >
          {media.isLoading ? (
            <Skeleton dataHcName={`${dataHcName}-media-skeleton`} />
          ) : (
            media.content
          )}
        </div>
        <div
          data-hc-name={`${dataHcName}-card-title-and-content`}
          className={contentClassNames}
        >
          {title && (
            <CardTitle dataHcName={`${dataHcName}-title`}>{title}</CardTitle>
          )}
          {children}
        </div>
      </>
    );

    return (
      <div
        ref={ref}
        data-hc-name={dataHcName}
        data-hc-event-section={dataHcEventSection}
        className={classNames(
          styles.Card,
          styles.contentFlex,
          media ? styles.withMedia : contentClassNames,
          className,
          {
            [styles.horizontal]: media?.horizontal,
            [styles.noShadow]: noBoxShadow,
          },
          theme?.Card,
        )}
        style={{ maxWidth, minWidth }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {!media && title && (
          <CardTitle dataHcName={`${dataHcName}-title`}>{title}</CardTitle>
        )}
        {media ? (
          media.horizontal ? (
            <div className={styles.HorizontalWrapper}>{mediaContent}</div>
          ) : (
            mediaContent
          )
        ) : (
          children
        )}
      </div>
    );
  },
);

export interface CardListProps {
  dataHcName?: string;
  className?: string;
  children: ReactNode;
}
export const CardList = ({
  dataHcName,
  className,
  children,
}: CardListProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.CardList, className)}
    >
      {children}
    </div>
  );
};
